<template>
  <div
    class="navbar-container d-flex content align-items-center py-1"
    style="padding-bottom:0.6rem;"
  >

    <!-- Left Col -->
    <div class="bookmark-wrapper align-items-center flex-grow-1 d-flex">
      <div class="d-flex">
        <h2
          class="text-primary ml-1 font-weight-bolder"
          style="padding-top:0.4rem;"
        >
          {{ $t('hubs_and_peers') }}
        </h2>
      </div>
    </div>

    <!-- Right Col -->
    <b-navbar-nav
      class="nav align-items-center ml-auto mr-1"
      style="padding-top:0.3rem;"
    >
      <a
        href="/"
        style="margin-bottom:0.18rem;margin-right:0.1rem;"
      >
        <feather-icon
          icon="HomeIcon"
          size="32"
          class="d-block"
        />
      </a>
    </b-navbar-nav>
  </div>
</template>

<script>
import {
  BNavbarNav, BButton, BTooltip,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  directives: {
    Ripple,
  },
  components: {
    BButton,
    BNavbarNav,
    BTooltip,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      userData: null,
    }
  },
  computed: {
    currentRouteName() {
      return this.$route.name
    },
  },
}
</script>
<style scoped>
.nav-item {
  color: #115642;
  transition: all 0.3s ease-in-out;
}
.nav-item:hover {
  color: #a70000
}
.navbar-container {
  backdrop-filter: blur(5px);
  background-color: rgba(255, 255, 255, 0.025);
}
</style>
